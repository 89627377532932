<template>
    <b-sidebar
        :visible="isSidebarActive"
        bg-variant="white"
        sidebar-class="sidebar-lg"
        shadow
        backdrop
        no-header
        no-close-on-backdrop
        no-close-on-esc
        right
        @hidden="resetForm"
        @change="(val) => $emit('update:is-sidebar-active', val)">

        <template #default="{ hide }">
                       
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">
                    <strong>{{ sidebarMode == 'add' ? 'AGREGAR CONTACTO DE EMPRESA CONTRATISTA' : 'EDITAR CONTACTO DE EMPRESA CONTRATISTA' }}</strong>
                </h5>

                <feather-icon
                    v-if="!processing"
                    class="ml-1 cursor-pointer"
                    icon="XIcon"
                    size="16"
                    @click="hide"
                />

            </div>

            <validation-observer #default="{ handleSubmit }" ref="refFormObserver">

                <b-form
                    class="p-2"
                    @submit.prevent="handleSubmit(onSubmit)"
                    @reset.prevent="resetForm"
                >

                    <!-- NAME(S) -->
                    <b-form-group
                        label="NOMBRE(S)"
                        label-for="names"
                    >
                        <validation-provider
                            #default="{ errors }"
                            rules="required"
                            name="nombre(s)"
                        >
                            <b-form-input
                                id="names"
                                v-model="formData.names"
                                placeholder="Ingrese el nombre ..."
                                :state="errors.length > 0 ? false : null"
                                trim
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>

                    <!-- SURNAME(S) -->
                    <b-form-group
                        label="APELLIDO(S)"
                        label-for="surnames"
                    >
                        <validation-provider
                            #default="{ errors }"
                            rules="required"
                            name="apellido(s)"
                        >
                            <b-form-input
                                id="surnames"
                                v-model="formData.surnames"
                                placeholder="Ingrese el apellido ..."
                                :state="errors.length > 0 ? false : null"
                                trim
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>

                    <!-- DOCUMENT NUMBER -->
                    <b-form-group
                        label="NÚMERO DE DOCUMENTO"
                        label-for="documentNumber"
                    >
                        <validation-provider
                            #default="{ errors }"
                            rules="required"
                            name="número de documento"
                        >
                            <b-form-input
                                id="documentNumber"
                                v-model="formData.documentNumber"
                                placeholder="Ingrese el número de documento ..."
                                :state="errors.length > 0 ? false : null"
                                trim
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>

                    <!-- EMAIL -->
                    <b-form-group
                        label="CORREO ELECTRÓNICO"
                        label-for="email"
                    >
                        <validation-provider
                            #default="{ errors }"
                            rules="required|email"
                            name="correo electrónico"
                        >
                            <b-form-input
                                id="email"
                                v-model="formData.email"
                                placeholder="Ingrese el correo electrónico ..."
                                :state="errors.length > 0 ? false : null"
                                trim
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>

                    <!-- CONTRACTING COMPANY -->
                    <b-form-group
                        label="EMPRESA CONTRATISTA"
                        label-for="contracting_company"
                    >
                        <validation-provider
                            #default="{ errors }"
                            rules="required"
                            name="empresa contratista"
                        >
                            <v-select
                                id="contracting_company"
                                v-model="formData.contracting_company"
                                :state="errors.length > 0 ? false : null"
                                :options="options.contractingCompanies"
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>

                    <!-- ACTIONS -->
                    <div class="d-flex mt-2">

                        <!-- BUTTON SUBMIT -->
                        <b-button-loading 
                            :text="sidebarMode == 'add' ? 'AGREGAR' : 'ACTUALIZAR'"
                            type="submit"
                            :processing="processing">
                        </b-button-loading>

                        <b-button
                            v-if="!processing"
                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                            type="button"
                            variant="outline-secondary"
                            class="ml-2"
                            @click="hide"
                        >
                            CANCELAR
                        </b-button>

                    </div>

                </b-form>

            </validation-observer>

        </template>

    </b-sidebar>
</template>

<script>

    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
    import BButtonLoading from '@core/components/b-button-loading/BButtonLoading.vue';
    import formValidation from '@core/comp-functions/forms/form-validation';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { useToast } from 'vue-toastification/composition';
    import { ref, watch } from '@vue/composition-api';
    import Ripple from 'vue-ripple-directive';
    import { required } from '@validations';
    import vSelect from 'vue-select';
    import store from '@/store';
    import axios from '@axios';
    
    export default {
        components: {
            ValidationProvider,
            ValidationObserver,

            BButtonLoading,

            required,
            vSelect
        },
        directives: {
            Ripple
        },
        model: {
            prop: 'isSidebarActive',
            event: 'update:is-sidebar-active',
        },
        props: {
            isSidebarActive: {
                type: Boolean,
                required: true,
            },
            mode: {
                type: String,
                required: true
            },
            data: {
                type: Object,
                required: false
            }
        },
        setup(props, { emit }) {

            // USE TOAST
            const toast = useToast();

            // REFS
            const processing = ref(false);
            const sidebarMode = ref('add');
            const options = ref({});

            const formData = ref({
                id: null,
                names: null,
                surnames: null,
                documentNumber: null,
                email: null,
                contracting_company: null
            });

            watch(() => props.data, async () => {
                sidebarMode.value = props.mode;
                formData.value = props.data;

                await getSelectContractingCompanies();

                if (props.mode === 'add') resetForm()
            });

            const getSelectContractingCompanies = async () => 
            {
                const { data } = await axios.get('/selects/contracting-companies');
                options.value = data;
            };

            const resetFormData = () => {
                formData.value = {
                    id: null,
                    names: null,
                    surnames: null,
                    documentNumber: null,
                    email: null,
                    contracting_company: null
                }
            };
            
            const onSubmit = () => {

                processing.value = true;

                const { id, names, surnames, documentNumber, email, contracting_company } = formData.value;

                const storeDispatch = `${ sidebarMode.value }Contractor`;

                const payload = {
                    names,
                    surnames,
                    documentNumber,
                    email,
                    contractingCompanyId: contracting_company.value
                }

                const data = sidebarMode.value === 'add' ? { payload } : { id, payload };
                
                store.dispatch(`app-contractor/${ storeDispatch }`, data)
                    .then( response => {

                        emit('refresh-data', id);

                        toast({
                            component: ToastificationContent,
                            props: {
                                title: response.data.message,
                                icon: 'CheckIcon',
                                variant: 'success'
                            }
                        });

                        emit('update:is-sidebar-active', false);

                    })
                    .catch( (err) => {
                        const message = err.response.data.message ? err.response.data.message : (sidebarMode.value === 'add' ? 'Error al agregar al contacto de la empresa contratista.' : 'Error al actualizar la información del contacto de la empresa contratista.');
                        
                        toast({
                            component: ToastificationContent,
                            props: {
                                title: message,
                                icon: 'AlertTriangleIcon',
                                variant: 'danger'
                            }
                        });
                    })
                    .finally( () =>  processing.value = false);

            };

            const {
                refFormObserver,
                getValidationState,
                resetForm,
            } = formValidation(resetFormData);

            return {
                processing,
                sidebarMode,
                options,

                formData,
                onSubmit,

                refFormObserver,
                getValidationState,
                resetForm
            }
        }
    }

</script>